import axios from '../utils/api'

// 获取登录二维码code
export function createQRlogin() {
  return axios({
    url: '/users/createQRlogin',
    method: 'GET'
  })
}

// 获取登录二维码code
export function getPcLoginToken(data) {
  return axios({
    url: '/users/GetPcLoginToken',
    method: 'POST',
    params: data
  })
}

// 获取当前登录用户基本信息
export function getUserByToken() {
  return axios({
    url: '/users/getUserByToken',
    method: 'POST'
  })
}

// 我的-全部产品列表
export function myAllProductList(data) {
  return axios({
    url: '/product/myAllProductList',
    method: 'GET',
    params: data
  })
}

// 我看别人-全部产品列表
export function myAllProductListByUserId(data) {
  return axios({
    url: '/product/myAllProductListByUserId',
    method: 'GET',
    params: data
  })
}

// 查询我/别人的收藏-分页
export function getCollectionPage(data) {
  return axios({
    url: '/collection/getCollectionPage',
    method: 'GET',
    params: data
  })
}

// 查询我/别人的点赞-分页
export function getLikePage(data) {
  return axios({
    url: '/like/getLikePage',
    method: 'GET',
    params: data
  })
}

// 删除产品
export function deleteProduct(data) {
  return axios({
    url: '/product/deleteProduct',
    method: 'GET',
    params: data
  })
}

// 获取其他用户的基本信息
export function getShowUserById(data) {
  return axios({
    url: '/users/getShowUserById',
    method: 'POST',
    params: data
  })
}

// 手机号登录
export function phoneLogin(data) {
  return axios({
    url: '/users/phonelogin',
    method: 'POST',
    params: data
  })
}

//发送手机验证码
export function sendPhoneCode(data) {
  return axios({
    url: '/users/sendPhoneCode',
    method: 'GET',
    params: data
  })
}

//修改用户信息
export function updateUser(data) {
  return axios({
    url: '/users/updateUser',
    method: 'POST',
    data
  })
}

//ai系统-获取用户分组专属信息
export function memberGroupParameter(data) {
  return axios({
    url: 'api/memberGroupParameter/login',
    method: 'GET',
    params:data,
    urlType:'ai'
  })
}