import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决Vue路由重复跳转报错
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(() => {})
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index'),
    meta:{
      title: 'MARSLAB - 火星时尚'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index'), // 登录页
    meta:{
      title: '登录 - MARSLAB'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/index'), // 搜索
  },
  {
    path: '/product/list/vertical',
    name: 'vertical',
    component: () => import('@/views/product/list/vertical/index'), // 产品列表-竖向
  },
  {
    path: '/product/list/horizontal',
    name: 'horizontal',
    component: () => import('@/views/product/list/horizontal/index') // 产品列表-横向
  },
  {
    path: '/product/details/planning/:id',
    name: 'planning',
    component: () => import('@/views/product/details/planning/planningDetails') // 趋势详情
  },
  {
    path: '/product/details/activity/:id',
    name: 'activity',
    component: () => import('@/views/product/details/activity/activityDetails') // 活动详情
  },
  {
    path: '/product/details/style/:id',
    name: 'style',
    component: () => import('@/views/product/details/style/styleDetails') // 图库详情
  },
  {
    path: '/product/details/video/:id',
    name: 'video',
    component: () => import('@/views/product/details/video/videoDetails') // 视频详情
  },
  {
    path: '/product/details/work/:id',
    name: 'work',
    component: () => import('@/views/product/details/work/workDetails') // 作品详情
  },
  {
    path: '/product/release/:type',
    name: 'release',
    component: () => import('@/views/product/release/index'), // 发布页
    meta:{
      title: '发布 - MARSLAB'
    }
  },
  {
    path: '/user/selfInfo',
    name: 'selfInfo',
    component: () => import('@/views/user/selfInfo/index') // 自己的信息
  },
  {
    path: '/user/otherInfo',
    name: 'otherInfo',
    component: () => import('@/views/user/otherInfo/index') // 别人的信息
  },
  {
    path: '/user/creator',
    name: 'creator',
    component: () => import('@/views/user/creator/index'), // 创作者列表
    meta:{
      title: '创作者 - MARSLAB'
    }
  },
  {
    path: '/doc/serviceAgreement',
    name: 'serviceAgreement',
    component: () => import('@/views/doc/serviceAgreement'), // 火星MARSLAB服务协议
    meta:{
      title: '火星MARSLAB服务协议'
    }
  },
  {
    path: '/doc/paidSubscription',
    name: 'paidSubscription',
    component: () => import('@/views/doc/paidSubscription'), // 付费订阅购买协议
    meta:{
      title: '付费订阅购买协议'
    }
  },
  {
    path: '/doc/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/doc/privacyPolicy'), // MARSLAB-隐私政策
    meta:{
      title: 'MARSLAB-隐私政策'
    }
  },
  {
    path: '/subscriptionMember',
    name: 'subscriptionMember',
    component: () => import('@/components/subscriptionMember') // 趋势会员
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
