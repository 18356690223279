import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';

import './globalRouter'
import './style/index.less'

import publicFnc from "./utils/public.js"
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.prototype.$publicFnc = publicFnc

Vue.config.productionTip = false

// 按需引入elementui组件
import {
  Tooltip,
  Input,
  Progress,
  MessageBox,
  Message,
  Dialog,
  Select,
  Option,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Scrollbar,
  DatePicker,
  Button,
  Tag,
  Divider,
  Loading,
  Autocomplete,
  Drawer,
  Image,
  Upload,
  Col,
  Row,
  Card,
} from 'element-ui';
Vue.use(Col)
Vue.use(Row)
Vue.use(Card)
Vue.use(Tooltip)
Vue.use(Input)
Vue.use(Progress)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Scrollbar)
Vue.use(DatePicker)
Vue.use(Button)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(Loading)
Vue.use(Autocomplete)
Vue.use(Drawer)
Vue.use(Image)
Vue.use(Upload)
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.prototype.$messageBox = MessageBox
Vue.prototype.$message = Message

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
